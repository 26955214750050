<template>
	<Modal classes="modal--visual-content " class="modal--visual">
		<template #header>
			<VHeading level="2">
				Recover your <br />
				password
			</VHeading>
		</template>
		<template #content>
			<div>
				<VText
					size="big"
					weight="bold"
					class="modal-section__card__content__subtitle"
				>
					Enter your email address
				</VText>
				<form class="form__column" @submit.prevent="submit">
					<VDefaultField :error="errors.get('email')">
						<template #field>
							<VInput
								v-model="form.email"
								type="text"
								placeholder="Email"
								:error="errors.get('email')"
								@input="errors.clear('email')"
							/>
						</template>
					</VDefaultField>

					<VButton color="primary" type="submit">
						Send reset link
					</VButton>
				</form>
			</div>
		</template>
	</Modal>
</template>

<script>
import { mapState } from 'vuex';
import { RECOVER_PASSWORD } from '@/store/actions.type';
import { SET_ACTIVE_MODAL, OPEN_ALERT } from '@/store/mutations.type';

export default {
	name: 'RecoverPasswordModal',
	data() {
		return {
			form: {
				email: ''
			}
		};
	},
	computed: {
		...mapState({
			errors: state => state.auth.errors
		})
	},
	methods: {
		async submit() {
			await this.$store.dispatch(RECOVER_PASSWORD, this.form);
			this.$store.commit(SET_ACTIVE_MODAL, null);
			this.$store.commit(OPEN_ALERT, {
				type: 'success',
				title: 'Email sent',
				content: 'Please check your email.'
			});
		}
	}
};
</script>
